<template>
    <v-dialog v-model="isOpen" max-width="1000px" persistent>
        <v-card style="position: relative;">
            <v-card-title class="px-0 py-0 flex-column " style="position: sticky; top: 0; z-index: 1; background: #fff">
                <div class="px-4 py-4 d-flex" style="width: 100%">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="pt-0"
                        @click.stop="cerrarModal" 
                        :disabled="detalles.isLoading"
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </div>
                <v-divider style="width: 100%" />
            </v-card-title>
            <v-card-text class="px-4 pt-4 pb-0">
                <div class="px-1 py-1">
                    <div v-if="resumenInstitucion" class="d-flex flex-wrap flex-column justify-space-between flex-sm-nowrap flex-sm-row" style="gap: 16px;">
                        <div class="text-center flex-grow-1">
                            <h6 class="text-caption text-uppercase font-weight-bold" style="color: #7a7a7c">Monto reservado</h6>
                            <p class="mb-0 text-h6" style="color: var(--v-info-darken2)">
                                {{ toMoneyFormat(resumenInstitucion.monto_reservado) }}
                            </p>
                        </div>
                        <v-divider class="d-none d-sm-block" vertical />
                        <div class="text-center flex-grow-1">
                            <h6 class="text-caption text-uppercase font-weight-bold" style="color: #7a7a7c">Monto ejecutado</h6>
                            <p class="mb-0 text-h6" style="color: var(--v-info-darken2)">
                                {{ toMoneyFormat(resumenInstitucion.monto_ejecutado) }}
                            </p>
                        </div>
                    </div>
                    <DataTableComponent
                        :items="fondos"
                        :headers="headers"
                        :total_registros="totalRegistros"
                        :show_loading="detalles.isLoading"
                        v-models:pagina="paginacion.pagina"
                        v-models:select="paginacion.registrosPorPagina"
                        dense
                        no-gutters
                        class="mt-4"
                        @paginar="manejarPaginacion"
                    >
                        <template v-slot:item.monto_establecido="{ item }">
                            {{ toMoneyFormat(item.monto_establecido) }}
                        </template>
                        <template v-slot:item.monto_disponible="{ item }">
                            {{ toMoneyFormat(item.monto_disponible) }}
                        </template>
                        <template v-slot:item.monto_ejecutado="{ item }">
                            {{ toMoneyFormat(item.monto_establecido - item.monto_disponible) }}
                        </template>
                        <template v-slot:item.acciones="{ item }">
                            <div class="d-flex align-center justify-center" style="gap: 8px;">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-on="on" v-bind="attrs" icon :to="'/reporteria-compra-en-linea/registros/' + item.id">
                                            <v-icon color="secondary">mdi-eye</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Ver registros</span>
                                </v-tooltip>
                            </div>
                        </template>
                    </DataTableComponent>
                </div>
                <div 
                    class="d-flex justify-center align-center py-4" 
                    style="position: sticky; bottom: 0; background: white; gap: 16px;"
                >
                    <v-btn 
                        color="primary" 
                        large
                        @click.stop="cerrarModal"
                        :disabled="detalles.isLoading"
                    >
                        Cerrar
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import { createPageable, setPageableResponse, togglePageable } from '@/utils/loadable';
import DataTableComponent from '@/components/DataTableComponent.vue';
import { toMoneyFormat } from '@/utils/data';

export default {
    components: { DataTableComponent },
    props: {
        isOpen: { type: Boolean },
        eventoBusqueda: { type: String, required: true },
        listener: { type: Object, required: true },
    },
    data: () => ({
        detalles: createPageable(null, 10),
        paginacion: {
            pagina: 1,
            registrosPorPagina: 10,
        },
    }),
    computed: {
        headers() {
            return [
                { align: 'center', sortable: false, text: 'Nombre', value: 'nombre' },
                { align: 'center', sortable: false, text: 'Monto reservado', value: 'monto_establecido' },
                { align: 'center', sortable: false, text: 'Monto disponible', value: 'monto_disponible' },
                { align: 'center', sortable: false, text: 'Monto ejecutado', value: 'monto_ejecutado' },
                { align: 'center', sortable: false, text: 'Motivo', value: 'motivo' },
                { align: 'center', sortable: false, text: 'Acciones', value: 'acciones' },
            ];
        },
        fondos() {
            if (Array.isArray(this.detalles.data?.fondos)) {
                return this.detalles.data.fondos;
            }

            return [];
        },
        resumenInstitucion() {
            return this.detalles.data?.institucion ?? null;
        },
        totalRegistros() {
            return this.detalles.pagination.total_rows;
        },
    },
    methods: {
        // UI
        toMoneyFormat,
        cerrarModal() {
            this.$emit('update:is-open', false);
        },
        manejarPaginacion(paginacion) {
            const { pagina, cantidad_por_pagina: registrosPorPagina } = paginacion;
            this.paginacion = { pagina, registrosPorPagina }

            this.cargarDetalleInstitucion();
        },
        // DATA
        async cargarDetalleInstitucion() {
            if (!this.institucion) return;

            const filtros = {
                pagination: true,
                per_page: this.paginacion.registrosPorPagina,
                page: this.paginacion.pagina,
                id_anio: this.anio,
            }

            togglePageable(this.detalles);
            const { data, headers } = await this.services.ReporteriaCompraEnLinea.cargarFondosInstitucionales(this.institucion, filtros);
            setPageableResponse(this.detalles, data, headers);
        },
    },
    watch: {
        institucion() {
            this.detalles = createPageable(null, 10);
            this.paginacion.pagina = 1;

            this.cargarDetalleInstitucion();
        },
    },
}
</script>
