<template>
    <v-card style="height: 100%; min-height: 350px;" class="chart-card">
        <DarkOverlayLoader v-if="montosPorAnio.isLoading" />
        <v-card-text class="chart-card-scroller">
            <div class="chart-card-content">
                <apexchart
                    width="100%" 
                    type="bar" 
                    :options="options" 
                    :series="series"
                />
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
import DarkOverlayLoader from '@/components/utils/DarkOverlayLoader.vue';
import { toMoneyFormat } from '@/utils/data';
import { obtenerNombreDeMes } from '@/utils/datetime';
import { createLoadable, setLoadableResponse, toggleLoadable } from '@/utils/loadable';

export default {
    name: 'EstadisticasAnualesCompraEnLinea',
    components: { DarkOverlayLoader },
    props: {
        filtros: { type: Object },
        nombreAnio: { type: [String, Number] },
    },
    data: () => ({
        montosPorAnio: createLoadable([]),
    }),
    computed: {
        numberFormatter() {
            return new Intl.NumberFormat('es', { notation: 'compact', maximumFractionDigits: 1 });
        },
        nombresMeses() {
            const meses = Array.from({ length: 12 });

            return meses.map((_, index) => obtenerNombreDeMes(index + 1));
        },
        series() {
            const data = this.montosPorAnio.data.map((montoMensual) => montoMensual.monto);

            return [{ name: 'Montos', data }];
        },
        options() {
            const formatter = this.numberFormatter;

            return ({
                chart: {
                    height: 350,
                    type: 'bar',
                },
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return formatter.format(val);
                    },
                    offsetY: -20,
                    style: {
                        fontSize: '12px',
                        colors: ["#304758"]
                    }
                },
                xaxis: {
                    categories: this.nombresMeses,
                    position: 'bottom',
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    crosshairs: {
                        fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#D8E3F0',
                            colorTo: '#BED1E6',
                            stops: [0, 1000],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        }
                        }
                    },
                    tooltip: {
                        enabled: true,
                    }
                },
                yaxis: {
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        // show: false,
                        formatter: function (val) {
                            return toMoneyFormat(val);
                        }
                    },
                },
                title: {
                    text: `Montos compra en línea ${this.nombreAnio ? `año ${this.nombreAnio}` : ''}`,
                    floating: true,
                    offsetY: 0,
                    align: 'center',
                    style: {
                        color: '#444'
                    },
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                },
            })
        },
    },
    methods: {
        async cargarEstadisticas() {
            toggleLoadable(this.montosPorAnio);
            const filtros = {
                id_institucion: this.filtros.institucion,
            }
            const { data } = await this.services.ReporteriaCompraEnLinea.cargarEstadisticasAnuales(this.filtros.anio, filtros);
            setLoadableResponse(this.montosPorAnio, data);
        },
    },
    watch: {
        filtros: {
            handler(value) {
                if (!value.anio) {
                    this.montosPorAnio = createLoadable([]);
                    return;
                }

                this.cargarEstadisticas();
            },
            deep: true,
            immediate: true,
        },
    },
}
</script>
<style>
@media (max-width: 1264px) {
    .chart-card {
        max-width: 100%;
        overflow: hidden !important;
    }

    .chart-card-scroller {
        overflow: auto;
        max-width: 100%;
    }
    
    .chart-card-content {
        min-width: 1000px;
    }
}
</style>